<template>
  <div class="d-flex">
    <div class="w-50 vh-100" id="login-image-left"></div>
    <div class="w-50 vh-100 text-start" id="login-right">
      <section id="login-rtop">
        <img src="../../assets/uc-logo.png" width="320" />
        <p class="pt-3" v-if="loggingIn" style="height: 82px">
          <i class="fa fa-spin fa-spinner"></i> Even wachten...
        </p>
        <p class="pt-3" v-else>
          Lees meer over onze dienstverlening op de website<br>
          van <a href="#" class="text-inherit">Adnamics</a> of gebruik een van onderstaande opties<br>
          om in te loggen op uw account.
        </p>
        <div>
          <button type="button" class="btn btn-login px-3 py-2" @click="getAuthUrl('google')" :disabled="loggingIn">
            <img src="../../assets/ggl.png" height="20" />Sign in with Google
          </button>
          <button type="button" class="btn btn-login ms-3 px-3 py-2" @click="getAuthUrl('microsoft')" :disabled="loggingIn">
            <img src="../../assets/mcrsft.png" height="20" />Sign in with Microsoft
          </button>
        </div>
      </section>
      <section id="login-rbottom">
        <p>
          <i class="far fa-lock"></i>&ensp;U logt in via een beveiligde verbinding middels uw Google- of Microsoft account.
        </p>
        <p class="text-dlight">
          Problemen met inloggen? <a href="#" class="text-inherit">Neem contact op</a>.
        </p>
      </section>
    </div>
  </div>
</template>
<style scoped>
#login-image-left{
  background-color: #3c98dc;
  background-image: url("../../assets/waitress/waitress1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#login-right{
  padding-left: 5vw
}
#login-rtop{
  height: 75%;
  padding-top: calc(40vh - 200px)
}
#login-rbottom{
  height: 25%;
}
.btn-login{
  border-color: #979797;
  border-radius: 0;
  display: inline-block;
  text-align: start;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.5px;
  height: 40px
}
.btn-login:hover{
  background-color: var(--uni-dark);
  border-color: var(--uni-dark);
  color: #fff
}
.btn-login img{
  margin-right: 10px
}
.btn-login:disabled{
  opacity: 33%
}
.fa-lock{
  font-size: 13px;
  color: #00d46e
}
</style>

<script>
import authService from '@/services/AuthService'
import store from '@/store/user'
import http from '@/http-common'

export default {
    data() {
        return {
          loggingIn: false
        }
    },
    methods: {
          getAuthUrl(loginType){
            this.loggingIn = true;
            let redirect = window.location.href;
            if(redirect.includes('callback')){
              redirect = window.location.origin;
            }
            window.sessionStorage.setItem('loginRedirect', redirect);
            authService.getAuthUrl(loginType).then(response => {
                location.assign(response.data.url);
            }).catch(error => {
                this.toastError(error)
            });
          },
          handleCallback(code, state, path) {
              const loginType = path.substring(10);
              this.loggingIn = true;
              authService.processCallback(code, state, loginType).then(response => {
                  store.dispatch('userLogin', response.data);
                  http.defaults['headers']['Authorization'] = store.getters.getAuthHeader;
                  this.loggedIn = true;
                  sessionStorage.setItem("authHeader", store.getters.getAuthHeader);
                  const loginRedirect = sessionStorage.getItem("loginRedirect");
                  if(loginRedirect){
                    sessionStorage.removeItem("loginRedirect");
                    window.location = loginRedirect;
                  }
                  else{
                    this.$router.push({ name: "Home" });
                  }
              }).catch(error => {
                  this.toastError(error)
                  this.loggingIn = false;
              })
        },
    },
    mounted() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');

      // const sessionState = urlParams.get('session_state');
      if (code && !location.href.includes('/locaties/callback')) {
        this.handleCallback(code, state, window.location.pathname)
      }
    }
}
</script>