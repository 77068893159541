import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dataStore from './store/data'
import moment from 'moment'
import vueToast from 'vue-toast-notification'
import PrimeVue from 'primevue/config'
import { createI18n } from 'vue-i18n'
import messages from './locales'
import 'vue-toast-notification/dist/theme-default.css'
import "bootstrap/dist/css/bootstrap.min.css"
import './assets/style.css'
import * as Sentry from "@sentry/vue";

import FormItem from '@/components/formitem/FormItem.vue'

// Type-define 'nl' as the master schema for translations
type MessageSchema = typeof messages.nl
const i18n = createI18n<[MessageSchema], 'nl'>({
    locale: 'nl',
    messages: messages
 })

const app = createApp(App).use(PrimeVue).use(vueToast).use(store).use(dataStore).use(router).use(i18n).mixin({
    methods: {
        formatDate (value: string, type: string) {
            if (value === null) return ''
            const definedMoment = moment(value).locale('nl')
            if (type === 'dateTime') {
                return definedMoment.format('DD-MM-YYYY HH:mm:ss')
            } else if (type === 'fromNow') {
                return definedMoment.fromNow()
            } else if (type === 'isoDate') {
                return definedMoment.format('YYYY-MM-DD')
            } else if (type === 'shortDate') {
                return definedMoment.format('DD-MM-YYYY')
            } else if (type === 'longDate') {
                return definedMoment.format('D MMMM YYYY')
            } else if (type === 'verylongDate') {
                return definedMoment.format('dddd, D MMMM YYYY')
            } else if (type === 'overlongDate') {
                return definedMoment.format('dddd D MMMM YYYY - HH:mm uur')
            } else if (type === 'statusDateTime') {
                return definedMoment.format('D MMMM YYYY') + '\n' + definedMoment.format('HH:mm uur')
            } else if (type === 'tinyDate') {
                return definedMoment.format('DD-MM')
            } else if (type === 'time') {
                return definedMoment.format('HH:mm uur')
            } else if (type === 'timeShort') {
                return definedMoment.format('HH:mm')
            } else if (type === 'logDateTime') {
                return definedMoment.format('DD-MM-YYYY - HH:mm')
            } else if (type === 'balloonDate') {
                return definedMoment.format("dd DD MMM 'YY")
            }
            return definedMoment.format('YYYY-MM-DD')
        },
        formatCurrency (value: number | string, withSymbol: boolean) {
            const nvalue = typeof value == 'string' ? Number(value.replace(',', '.')) : value;
            return (withSymbol ? '€ ' : '') + new Intl.NumberFormat('en-NL', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(nvalue);
        },
        formatNumber (value: number | string) {
            const nvalue = typeof value == 'string' ? Number(value.replace(',', '.')) : value;
            return new Intl.NumberFormat('en-NL', {minimumFractionDigits: 0, maximumFractionDigits: 0}).format(nvalue);
        },
        fullLocationName(loc: any, modalName: any = false){
            if(modalName && modalName._value && modalName._value.name){
                const name = modalName._value.name;
                if(name == "Dagstaten" && loc.location) return loc.location.full_name;
                if(name == "Locaties") return (loc.franchise ? (loc.franchise.name + ' ') : '') + loc.name;
                return loc.name;
            }
            return (loc.franchise ? (loc.franchise.name + ' ') : '') + loc.name;
        },
        preventNonNumbers(e: KeyboardEvent, allowCommas = true, allowNegative = false){
            if(['Backspace', 'Left', 'ArrowLeft', 'Right', 'ArrowRight', 'Tab', 'Home', 'End'].includes(e.key)){
                return;
            }
            if(e.ctrlKey || e.metaKey){
                return;
            }
            let regex = /[^0-9]/;
            if(allowCommas && allowNegative) regex = /[^.\-0-9]/;
            else if(allowNegative) regex = /[^\-0-9]/;
            else if(allowCommas) regex = /[^.0-9]/;
            if(regex.test(e.key)){
                e.preventDefault();
            }
            if(allowCommas && e.key === ','){
                (e.target as (HTMLInputElement)).value += '.';
            }
        },
        async toastError(error: any) {
            let message = '';

            if(error.response && error.response.data && error.response.data.message) {
                message = error.response.data.message;
                message += "<br>"
                let errorkey = null;
                for(errorkey in error.response.data.errors) {
                    message += " - " + error.response.data.errors[errorkey] + "<br>";
                } 
            } else if(error.response && error.response.data && error.response.data instanceof Blob) {
                message = await error.response.data.text();
                const messageJSON = JSON.parse(message);
                message = (messageJSON && messageJSON.message) ? messageJSON.message : 'Unknown error occurred';
            } else {
                message = typeof error === 'string' ? error : 'Unknown error occurred';
            }

            this.$toast.error(message, {'duration': 2000 + (message.length * 60)});
        },
        async toastSuccess(message: any) {
            this.$toast.success(message)
        },
        triggerDownload(responseData: any, name: string){
            const url = window.URL.createObjectURL(new Blob([responseData]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", name);
            document.body.appendChild(link);
            link.click();
        }
    }
});
app.component("FormItem", FormItem);

if(process.env.VUE_APP_SENTRY_DNS) {
Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost:8080", "localhost:8081", "https://uniconnect.netvibestest.net", "https://uniconnect.eu"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
app.mount('#app')
